<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">

        <div class="row">
            <div class="col-12">
                <validated-text-area label="Paragraph 1*" v-model="model.paragraph_1"
                                     :rules="{required: true, max:155}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Paragraph 2*" v-model="model.paragraph_2"
                                     :rules="{required: true, max:175}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" label="Image*" name="Image" v-model="model.image" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                     loading-text="Saving..." :disabled="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'AddVisionMission',

    data () {
        return {
            addUrl : urls.cms.visionMission.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Vision And Mission..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
