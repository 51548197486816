<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>VISION AND MISSION</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.vmAddModal.show()" text="Add"/>
            </div>
        </div>

        <custom-vue-table :show-search-box="false" :fields="fields" :url="listUrl"
                          class="c-cms-table d-sm-none-lg-block" :per-page="10" ref="table">

            <template #image="{rowData}">
                <div class="container-image fl-x-cc pos-r" @click="viewFile(rowData.image)">
                    <img :src="rowData.image" alt="Avatar" class="c-image cursor-pointer">
                    <div class="c-overlay">
                        <div class="c-icon ml-3">
                            <i class="fa fa-eye cursor-pointer"></i>
                        </div>
                    </div>
                </div>
            </template>

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table>
        <vision-mission-mobile-view @viewDetails="viewDetails" @setDelete="setDelete"
                                    class="d-lg-none" ref="visionMissionMobileView"></vision-mission-mobile-view>
        <modal title="Add Vision And Mission" ref="vmAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddVisionMission @success="formSuccess"></AddVisionMission>
        </modal>

        <modal title="Edit Vision And Mission" ref="vmEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditVisionMission :initial-data="editingItem" @success="formSuccess"></EditVisionMission>
        </modal>

        <delete-modal ref="vmDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Vision And Mission <b v-html="deletingItem && deletingItem.content_text"></b>.
                Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.content_text"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddVisionMission from './AddVisionMission';
import EditVisionMission from './EditVisionMission';
import VisionMissionMobileView from '@/views/cms/about-us/vision-mission/VisionMissionMobileView';

export default {
    name : 'VisionMission',

    components : { VisionMissionMobileView, AddVisionMission, EditVisionMission },

    data () {
        return {
            listUrl   : urls.cms.visionMission.list,
            deleteUrl : urls.cms.visionMission.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name  : '__slot:image',
                    title : 'Image'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/cms/vision-and-mission/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.vmAddModal.close();
            refs.vmEditModal.close();
            refs.table.refreshTable();
            refs.visionMissionMobileView.loadData();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.vmEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.vmDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted Vision And Mission!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.vmDeleteModal.close();
            this.$refs.table.refreshTable();
            this.$refs.visionMissionMobileView.loadData();
        }

    }
};
</script>

<style scoped>

</style>
